import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBBtn,
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import Navbar from './Views/Navbar'
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'
export default function Home() {

  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  var userType = localStorage.getItem('userType')
  userType = JSON.parse(userType)
  
  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)

  return (
    <MDBCard className='text-center'>
      <MDBCardHeader>
       {/* <MDBTabs pills className='card-header-tabs'>
          <MDBTabsItem>
            <MDBTabsLink active>
              Active
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink>
              Link
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink className='disabled'>
              Disabled
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>*/}
        <Navbar />
      </MDBCardHeader>
      <MDBCardBody>
       

        <MDBCarousel showControls showIndicators dark fade>

      <MDBCarouselItem        
        itemId={1}
        src={require('./img/0.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={2}
        src={require('./img/1.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={3}
        src={require('./img/2.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={4}
        src={require('./img/3.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={5}
        src={require('./img/4.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={6}
        src={require('./img/5.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={7}
        src={require('./img/6.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={8}
        src={require('./img/7.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={9}
        src={require('./img/8.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={10}
        src={require('./img/9.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={11}
        src={require('./img/10.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={12}
        src={require('./img/11.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={13}
        src={require('./img/12.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={14}
        src={require('./img/13.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={15}
        src={require('./img/14.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={16}
        src={require('./img/15.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={17}
        src={require('./img/16.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={18}
        src={require('./img/17.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={19}
        src={require('./img/18.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={20}
        src={require('./img/19.jpg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={21}
        src={require('./img/20.png')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={22}
        src={require('./img/21.jpeg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>


      
      <MDBCarouselItem        
        itemId={23}
        src={require('./img/22.JPG')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={24}
        src={require('./img/23.JPG')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={25}
        src={require('./img/24.JPG')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      
      <MDBCarouselItem        
        itemId={26}
        src={require('./img/25.JPG')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>

      <MDBCarouselItem        
        itemId={27}
        src={require('./img/26.jpeg')} 
        alt='2023 SDGS Youth Summit'
      >
      </MDBCarouselItem>
      
    </MDBCarousel>
      </MDBCardBody>
    </MDBCard>
  );
}