import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow, MDBIcon,
  } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
  import $ from 'jquery'
const ViewIntrestedParticipants = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    var userType = localStorage.getItem('userType')
    userType = JSON.parse(userType)
    
    var userID = localStorage.getItem('userID')
    userID = JSON.parse(userID)

    var position = localStorage.getItem('position')
    position = JSON.parse(position)

    const [handleSubmitBtnLoader, setHandleSubmitBtnLoader] = useState('')
    const [tableRefresh, setTableRefresh] = useState('')
    //let [participantsFile, setParticipantsFile] = useState('')
    const[getParticipantsID, setGetParticipantsID] = useState('')   
    const [DBtableViewParticipants, setDBtableViewParticipants] = useState([])
    
    let [companyCode, setCompanyCode] = useState('')
    let [REF, setREF] = useState('')
    let [amountPaid, setAmountPaid] = useState('')
    //let [paymentStatus, setPaymentStatus] = useState('')

    var [firstName, setfirstName] = useState('')
    var [middleName, setmiddleName] = useState('')
    var [lastName, setlastName] = useState('')

    $.DataTable = require('datatables.net')

    const handleSubmitUpdatePaidParticipant = async e => {  
      setHandleSubmitBtnLoader('Loading')    
      /*setCompanyCode('')
      setREF('')
      setAmountPaid('')*/
      var formData = new FormData(this)
      formData.append('apiType', 'postParticipantPaid')
      formData.append('id', getParticipantsID)
      formData.append('companyCode', companyCode)
      formData.append('REF', REF)
      formData.append('amountPaid', amountPaid)
      //formData.append('paymentStatus', paymentStatus)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setTableRefresh('Refresh')
        /*setCompanyCode('')
        setREF('')
        setAmountPaid('')
        setPaymentStatus('')
        */
        alert(response.data)

    }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));



    var formDataRefresh = new FormData(this)
            formDataRefresh.append('apiType', 'viewParticipants')
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/index.php`,
              data: formDataRefresh,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(responseRefresh => {
              //alert(response.data)
                setHandleSubmitBtnLoader('')
                setDBtableViewParticipants(responseRefresh.data)
                setTimeout(function () {
                  $.noConflict();
                  $('#table').DataTable({
                    retrieve: true, // this hides the errors
                    pageLength: 10,
                    lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
                  })
                  //addTableButtons()
                }, 2000)

            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"),
            setHandleSubmitBtnLoader('')
            );
         



}

    useEffect(() => {
      setTableRefresh('')
    //const handleFormSubmit = async e => {
        //setHandleSubmitBtnLoader('Loading')
        
            var formData = new FormData(this)
            formData.append('apiType', 'viewParticipants')
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/index.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
              //alert(response.data)
                setHandleSubmitBtnLoader('')
                setDBtableViewParticipants(response.data)
                setTimeout(function () {
                  $.noConflict();
                  $('#table').DataTable({
                    retrieve: true, // this hides the errors
                    pageLength: 10,
                    lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
                  })
                  //addTableButtons()
                }, 2000)

            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"),
            setHandleSubmitBtnLoader('')
            );
         
      //};
    }, [tableRefresh])


    /*function addTableButtons () {
      //$.noConflict();
      var table = $('#table').DataTable()
  
        new $.fn.dataTable.Buttons(table, {
          buttons: [
            {
              extend: 'copy',
              text: 'Copy <i class="fa fa-files-o"></i>',
              className: 'dtButton',
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
              }
            },
            {
              extend: 'excel',
              text: 'Excel <i class="fa fa-file-excel-o"></i>',
              className: 'dtButton',
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
              }
            },
            //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
            {
              extend: 'pdf',
              text: 'PDF <i class="fa fa-file-pdf-o"></i>',
              className: 'dtButton',
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
              }
            },
            {
              extend: 'print',
              text: 'Print <i class="fa fa-print"></i>',
              className: 'dtButton',
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
              }
            }
          ]
        })
  
        table.buttons(0, null).containers().appendTo('#filterTopCriteria')
      

  }*/
  const handleGetParticipantsID = id => {
    setGetParticipantsID(id);
  };

  useEffect(() => {
    setCompanyCode('')
    setREF('')
    setAmountPaid('')
    //setPaymentStatus('')
    //viewGetModalParticipantsName
    //alert(getParticipantsID)
    var formData = new FormData(this)
      formData.append('apiType', 'viewGetModalParticipants')
      formData.append('id', getParticipantsID)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        //alert(response.data.firstName)
        setfirstName(response.data.firstName)
        setmiddleName(response.data.middleName)
        setlastName(response.data.lastName)
        setCompanyCode(response.data.companyCode)
        setREF(response.data.REF)
        setAmountPaid(response.data.amountPaid)
       // setPaymentStatus(response.data.paymentStatus)
    }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
}, [getParticipantsID])

let paymentStatusDB = '';
  return (
    <>        

    <section className=''>
          {/*Loader Starts Here*/}
          <div hidden={handleSubmitBtnLoader ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>
        {/*Loader Ends Here*/} 

      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'  style={{ overflowX: 'auto' }}>
                <Navbar />
                  <div className='mt-1'>
                    <h5 className='font-weight-500 my-1 py-1'>
                    View Intrested Participants
                    </h5>
                  </div>


                                        {/*<!-- Modal: Add Paid Participant Starts Here  -->*/}
                                        <div
                      class='modal fade col-lg-12'
                      id='modalAddPaidParticipant'
                      tabindex='-1'
                      role='dialog'
                      aria-labelledby='myModalLabel'
                      aria-hidden='true'
                    >
                      <div class='modal-dialog cascading-modal' role='document'>
                        {/*<!-- Content -->*/}
                        <div class='modal-content'>
                          {/* <!-- Header -->*/}
                          <div class='modal-header ocean blue-gradient white-text '>
                            <h4 class=''>Add Paid Details : </h4>
                            
                            <button
                              type='button'
                              class='close waves-effect waves-light'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>                            
                          </div>
                          {/*<!-- Body -->*/}
                          <div class='modal-body mb-0 '>
                          <p>{lastName} {middleName} {firstName} </p>
                          

                  {/*<!--Grid row--> */}
                  <div class='row'>
                          <div class='col-md-12 mt-1'>
                              <MDBInput
                                type='text'
                                class='form-control'
                                onChange={e => setCompanyCode(e.target.value) }
                                value={companyCode}
                                label = 'Company Code'
                              />
                          </div>
                          </div>

                          <div class='row'>
                          <div class='col-md-12 mt-3'>
                                <MDBInput
                                type='text'
                                class='form-control'
                                onChange={e => setREF(e.target.value) }
                                value={REF}
                                label = 'REF'
                              />
                          </div>
                          </div>

                          <div class='row'>
                          <div class='col-md-12 mt-3'>
                            <MDBInput
                                type='text'
                                class='form-control'
                                onChange={e => setAmountPaid(e.target.value) }
                                value={amountPaid}
                                label = 'Amount Paid'
                              />
                          </div>
                        </div>

                      {/*  <div class='row'>
                          <div class='col-md-12 mt-3'>
                            <lable>Payment Status</lable>
                            <select
                                type='text'
                                class='custom-select d-block w-100'
                                onChange={e => setPaymentStatus(e.target.value)}
                                value={paymentStatus}
                              >
                                <option  value="">Choose...</option>
                                <option>Partial Payment</option>
                                <option>Full Payment</option>
                              </select>
                          </div>
                        </div> */}
                  {/*<!--\Grid row--> */}

                  <div class='row mt-3'>
                  <div class='col-md-12'>
                              <div class='md-form'>
                                <button
                                  class='btn white-text blue accent-2 col-md-12'
                                  onClick={handleSubmitUpdatePaidParticipant}
                                  disabled={companyCode === '' || REF === '' || amountPaid === '' || 
                                amountPaid < 0 || amountPaid === '0'
                                }
                                >
                                  
                                
                            <span class='fa fa-update'> Update</span>
                                </button>
                              </div>
                            </div>
                  </div>

                          </div>
                          {/*Modal Body Ends Here*/}
                        </div>
                      </div>
                      {/*<!-- Content -->*/}
                    </div>
                    {/* <!-- Modal: Add Paid Participant Ends Here .-->*/}


                  <div class='text-center'>
                    <hr class='mt-1' />
                    
                    
                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm nowrap'
                      width='100%'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        <tr>
                          <th>S/N</th>
                          <th hidden = {position != 'Executive Director' && position != 'Finance Director'}>Add Payment Details</th>
                         {/* <th>Payment Status</th> */}
                          <th>Company Code</th>
                          <th>REF</th>
                          <th>Amount Paid</th>
                          <th>Category</th>
                              <th>LastName</th>
                              <th>Middle Name</th>
                              <th>First Name</th>
                              <th>Age</th>
                              <th>Sex</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>WhatsApp Number</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Country</th>
                              <th>Occupation</th>
                              <th>Organization</th>
                              <th>Sector</th>
                              <th>Source Of Sponsorship</th>
                              <th>How Did You Hear About The Summit</th>
                              <th>Why Do You Want To Participate In The Summit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewParticipants.map((val, index) => {
                         /* paymentStatusDB = val.status;
                          if(paymentStatusDB === 'Partial Payment'){
                            paymentStatusDB = 'yellow'
                          }
                          else if (paymentStatusDB === 'Full Payment'){
                            paymentStatusDB = 'lime'
                          } else {
                              paymentStatusDB = ''
                          }*/
                          return (
                            <>
                            <tr>
                              {/*                              
                            <tr style={{backgroundColor: paymentStatusDB}}>
                              */}
                            <td>{index + 1}</td>
                              <td
                              hidden = {position != 'Executive Director' && position != 'Finance Director'}
                              type='button'
                              title='Click Here To Add Payment Details For This Participant'
                              data-toggle='modal'
                              data-target='#modalAddPaidParticipant'
                              onClick={() => {
                                handleGetParticipantsID(val.participantsID)
                              }}
                              
                              ><MDBIcon icon='pen-alt' />
                              </td>
                            {/*  <td>{val.status}</td> */}
                              <td>{val.companyCode}</td>
                              <td>{val.REF}</td>
                              <td>{val.amountPaid}</td>
                              <td>{val.category}</td>
                              <td>{val.lastName}</td>
                              <td>{val.middleName}</td>
                              <td>{val.firstName}</td>
                              <td>{val.age}</td>
                              <td>{val.sex}</td>
                              <td>{val.email}</td>
                              <td>{val.mobileNumber}</td>
                              <td>{val.whatsAppNumber}</td>
                              <td>{val.city}</td>
                              <td>{val.state}</td>
                              <td>{val.Country}</td>
                              <td>{val.occupation}</td>
                              <td>{val.organization}</td>
                              <td>{val.sector}</td>
                              <td>{val.sourceofSponsorship}</td>
                              <td>{val.howDidYouHearAboutTheSummit}</td>
                              <td>{val.whyDoYouWantToParticipateInTheSummit}</td>
                            </tr>
                            </>
                          )
                        })}
                      </tbody>
                      <tfoot class='form-header text-center ocean blue-gradient'>
                        <tr>
                          
                        </tr>
                      </tfoot>
                    </table>
                    
                    
                    
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default ViewIntrestedParticipants
