import React, { useState } from 'react';
import {
  MDBNavbar,  MDBContainer,  MDBNavbarBrand,  MDBNavbarToggler,  MDBNavbarItem, MDBCardText,
  MDBNavbarLink,  MDBIcon,  MDBCollapse,  MDBTabs,  MDBTabsItem,  MDBTabsLink, MDBCardTitle,MDBBtn
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'

export default function Navbar() {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

var userType = localStorage.getItem('userType')
userType = JSON.parse(userType)

var position = localStorage.getItem('position')
position = JSON.parse(position)

    var loggedIn = sessionStorage.getItem('loggedIn')
    loggedIn = JSON.parse(loggedIn)
    if(loggedIn != 'Yes'){
      navigate('/Logout')
    }

  const [showNavExternal, setShowNavExternal] = useState(false);

  const handleHome = async e => {
    navigate('/Home')
  };

  const handleRegisterUsers = async e => {
    navigate('/RegisterUsers')
  };

  const handleAddBulkyPayment = async e => {
    navigate('/AddBulkyPayment')
  };

  const hanldeCompleteRegistration = async e => {
    navigate('/CompleteRegistration')
};

const handleViewIntrestedParticipants = async e => {
    navigate('/ViewIntrestedParticipants')
};

const handleViewPaidParticipants = async e => {
    navigate('/ViewPaidParticipants')
};

const handleAddParticipants = async e => {
    navigate('/AddParticipants')
};

const handlePrintAttendanceID = async e => {
    navigate('/PrintAttendanceID')
};

const handleUpdateProfile = async e => {
    navigate('/UpdateProfile')
};

const handleRegisterParticipant = async e => {
  navigate('/RegisterParticipant')
};

const handleLogout = async e => {
    navigate('/Logout')
};


  return (
    <>
      <MDBCollapse show={showNavExternal}>
      <MDBTabs pills className='card-header-tabs'>
      <MDBTabsItem>
            <MDBTabsLink onClick={handleHome}>
              Home
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem hidden={userType === 'Participant' || position !='Executive Director'}>
            <MDBTabsLink onClick={handleRegisterUsers}>
              Register Users
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem hidden={userType === 'Participant' || position !='Executive Director'}>
            <MDBTabsLink onClick={handleAddBulkyPayment}>
            Add Bulky Payment
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={hanldeCompleteRegistration}>
              Complete Registration
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem hidden= {userType === 'Participant'}>
            <MDBTabsLink onClick={handleViewIntrestedParticipants}>
              View Intrested Participants
            </MDBTabsLink>
          </MDBTabsItem>
          
          <MDBTabsItem hidden = {userType === 'Participant'}>
            <MDBTabsLink onClick={handleViewPaidParticipants}>
              View Paid Participants
            </MDBTabsLink>
          </MDBTabsItem>

          <MDBTabsItem hidden = {userType === 'Participant'}>
            <MDBTabsLink onClick={handlePrintAttendanceID}>
              Print Attendance ID
            </MDBTabsLink>
          </MDBTabsItem>

          <MDBTabsItem hidden = {userType === 'Participant' || position != 'Executive Director' && position != 'Finance Director'}>
            <MDBTabsLink onClick={handleAddParticipants}>
              Add Participants
            </MDBTabsLink>
          </MDBTabsItem>

          <MDBTabsItem>
            <MDBTabsLink onClick={handleUpdateProfile}>
              Update Profile
            </MDBTabsLink>
          </MDBTabsItem>

          <MDBTabsItem hidden = {userType != 'Employee'}>
            <MDBTabsLink onClick={handleRegisterParticipant}>
            Register Participant
            </MDBTabsLink>
          </MDBTabsItem>

        </MDBTabs>
      </MDBCollapse>
      <MDBNavbar dark bgColor='dark'>
        <MDBContainer fluid>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarToggleExternalContent'
            aria-controls='navbarToggleExternalContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavExternal(!showNavExternal)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          
        <MDBCardText style={{color: 'white'}}>
         AAAZ - 5th AFRICAN YOUTH SDGS SUMMIT 2023
        </MDBCardText>
        <MDBBtn onClick={handleLogout}><span className='fa fa-sign-out'>Logout</span></MDBBtn>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}