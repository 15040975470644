import React, { useState } from 'react';
//import logo from './logo.svg';
import '../App.css';
import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'

function App() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  sessionStorage.removeItem('loggedIn');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('userType');
  localStorage.removeItem('userID');
  localStorage.removeItem('name');
  localStorage.removeItem('position');


  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async e => {
    e.preventDefault();
    //navigate('/ApplicationForm')
    //alert('Uname = '+user+ ' pWD = '+pwd)
   setHandleSubmitBtn('Loading')
    
    var formData = new FormData(this)
    formData.append('apiType', 'login')
    formData.append('username', user)
    formData.append('password', pwd)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/login.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      //alert(response.data)
      if(response.data.loggedIn === 'Yes'){
      setUser('')
      setPwd('')
      //alert(response.data)
      sessionStorage.setItem('loggedIn', JSON.stringify(response.data.loggedIn))
      localStorage.setItem('loggedIn', JSON.stringify(response.data.loggedIn))
      localStorage.setItem('userType', JSON.stringify(response.data.userType))
      localStorage.setItem('userID', JSON.stringify(response.data.userID))
      localStorage.setItem('name', JSON.stringify(response.data.name))
      localStorage.setItem('position', JSON.stringify(response.data.position))

      navigate('/Home')
    }
      else{      
      alert('Error : Failed To Login')
      //alert(response.data.name)
    }
    }).catch((err) => alert("Connection Error : Check Internet Connectivity")
    );
 
};

  
  return (
    <>
    <MDBNavbar light bgColor='dark'>
      <MDBContainer fluid>
        <marquee>
        <span className='navbar-text' style={{color: 'white'}}> ALLIANCE FOR ACCOUNTABILITY ADVOCATES ZAMBIA - 5th AFRICAN YOUTH SDGS SUMMIT 2023 </span>
      </marquee>
      </MDBContainer>
    </MDBNavbar>
    <div className='bg-image'>
     {/* <img src='https://mdbootstrap.com/img/new/standard/city/001.webp' className='w-100' height={600} alt='AAAZ' />*/}
      <img src={require('./img/aaaz.jpeg')} className='w-100' />
      <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <MDBRow tag="form" className='g-3'>
      <MDBCol md="7">
        <MDBInput
        style={{backgroundColor : 'white'}}
          onChange={e => setUser(e.target.value)}
          value={user}
          id='validationCustom01'
          label='Username'
        />
      </MDBCol>
      <MDBCol md="7">
      <MDBInputGroup>
        <MDBInput
        style={{backgroundColor : 'white'}}
        type={showPassword ? "text" : "password"}
        onChange={e => setPwd(e.target.value)}
        value={pwd}
        label='Password' />
        <span onClick={handleShowPassword}  rippleColor='dark'
        style={{backgroundColor : 'white', width: '12%', color : 'red', fontSize : '140%'}}
        >
          <center>
        {showPassword ? <MDBIcon icon='eye-slash' /> : <MDBIcon icon='eye' />}
          </center>
        </span>
      </MDBInputGroup>
      <p style={{float : 'right', color: 'white'}}><a href='ApplicationForm'>SignUp</a> </p>
      
      {/*</MDBCol>
      
      <MDBCol size="12"> */}
        <MDBBtn 
        className='mt-3'
        onClick={handleSubmit}
        disabled={user === '' || pwd === '' || handleSubmitBtn === 'Loading'}
        >
          <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
<span className='fa fa-sign-in'> Login</span>
          </MDBBtn>
      </MDBCol>
    </MDBRow>
        </div>
      </div>
    </div>

    </>
  );
}

export default App;
